import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WeScanServiceTypes } from '../../core/models/wescan/wescan-service-types';
import { CacheableHttpClient } from '../../core/services/cache/cacheable-http-client';
import { restEndPoint } from '../../core/core-constants.service';
import { WeScanDevices } from '../../core/models/wescan/wescan-devices';
import { WeScanProcedures } from '../../core/models/wescan/wescan-procedures';
import { map } from 'rxjs/operators';
import { DateUtilService } from '../../core/utils/date-util.service';
import { WeScanAvailabilitiesRequest } from '../../core/models/wescan/we-scan-availabilities-request';
import { WeScanAvailabilities } from '../../core/models/wescan/wescan-availabilities';
import { WeScanAvailabilitiesResponse } from '../../core/models/wescan/we-scan-availabilities-response';
import { WeScanViewModel } from '../../core/models/wescan/wescan-view-model';
import { WeScanRequests } from '../../core/models/wescan/wescan-requests';
import { HttpParams } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class WescanRestService {

  constructor(private http: CacheableHttpClient, private dateUtilService: DateUtilService) {
  }

  private readonly WESCAN_REST_ENDPOINT = restEndPoint + 'wescan';
  private readonly SERVICE_TYPES_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/service-types';
  private readonly DEVICES_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/devices';
  private readonly IS_WESCAN_USER_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/isWeScanUser';
  private readonly PROCEDURES_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/procedures?';
  private readonly AVAILABILITIES_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/availabilities';
  private readonly REQUESTS_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/requests';
  private readonly REQUEST_APPOINTMENTS_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/requestAppointments';
  private readonly CANCEL_APPOINTMENT_REST_ENDPOINT = this.WESCAN_REST_ENDPOINT + '/cancel';

  getServiceTypes(): Observable<WeScanServiceTypes> {
    return this.http.get<WeScanServiceTypes>(this.SERVICE_TYPES_REST_ENDPOINT);
  }

  getDevices(): Observable<WeScanDevices> {
    return this.http.get(this.DEVICES_REST_ENDPOINT);
  }

  isWeScanUser(): Observable<boolean> {
    return this.http.get<boolean>(this.IS_WESCAN_USER_REST_ENDPOINT);
  }

  getProcedures(deviceId: string, serviceType: string): Observable<WeScanProcedures> {
    return this.http.get<WeScanProcedures>(this.PROCEDURES_REST_ENDPOINT + 'deviceId=' + deviceId + '&serviceType=' + serviceType);
  }

  getAvailabilities(body: WeScanAvailabilitiesRequest): Observable<WeScanAvailabilities> {
    return this.http.post<WeScanAvailabilitiesResponse>(this.AVAILABILITIES_REST_ENDPOINT, body)
      .pipe(map((response: WeScanAvailabilitiesResponse) =>
        ({
          availabilities: (response.availabilities || []).map(slot => ({
            startDateTime: this.dateUtilService.convertUtcToLocal(slot.startDateTime),
            endDateTime: this.dateUtilService.convertUtcToLocal(slot.endDateTime)
          }))
        }))
      );
  }

  getWeScanRequestAppointments(): Observable<WeScanViewModel> {
    return this.http.get<WeScanViewModel>(this.REQUEST_APPOINTMENTS_REST_ENDPOINT, {cache: false}).pipe(
      map((response: WeScanViewModel) => ({
          requestAppointments: (response.requestAppointments || []).map(requestAppointment =>
            ({
              shortDescription: requestAppointment.shortDescription,
              description: requestAppointment.description,
              state: requestAppointment.state,
              requestDate: this.dateUtilService.convertUtcToLocal(requestAppointment.requestDate),
              nickname: requestAppointment.nickname,
              startDate: this.dateUtilService.convertUtcToLocal(requestAppointment.startDate),
              endDate: this.dateUtilService.convertUtcToLocal(requestAppointment.endDate),
              serviceType: requestAppointment.serviceType,
              procedures: requestAppointment.procedures,
              deviceId: requestAppointment.deviceId,
              onSideContact: requestAppointment?.onSideContact,
              radiologistContact: requestAppointment?.radiologistContact,
              requestor: requestAppointment?.requestor,
              appointmentId: requestAppointment.appointmentId
            })
          )
        }
      ))
    );
  }

  createWeScanRequest(weScanRequest: WeScanRequests): Observable<any> {
    this.http.clearCache(this.REQUESTS_REST_ENDPOINT);
    return this.http.post(this.REQUESTS_REST_ENDPOINT, weScanRequest, {observe: 'response'});
  }

  cancelAppointment(appointmentId: string): Observable<any> {
    return this.http.patch(this.CANCEL_APPOINTMENT_REST_ENDPOINT, null, {
      params: new HttpParams().set('appointmentId', appointmentId),
      observe: 'response'
    });
  }
}
